:root {
    --x: -100px;
    --y: -100px;
    --radius: 75px;
}

.canvas {
    position: fixed;
    top: 0;
    z-index: -1;
    clip-path: circle(var(--radius) at var(--x) var(--y));
}

.circle {
    position: absolute;
    top: calc(-1 * var(--radius));
    left: calc(-1 * var(--radius));
    width: calc(2 * var(--radius));
    height: calc(2 * var(--radius));
    transform: translate(var(--x), var(--y));
    z-index: -1;
}