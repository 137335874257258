.container {
    display: flex;
    justify-content: center;
    min-height: 64px;
    width: 100vw;
    backdrop-filter: blur(2px);
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
}

.logo {
    font-size: 64px;
    font-weight: 600;
    line-height: 64px;
    color: var(--color-main);
    user-select: none;
}

.nav {
    width: 100%;
    margin: 0 50px;
}

.list {
    position: relative;
    display: flex;
    list-style: none;
    justify-content: flex-end;
    user-select: none;
}

.list_item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 0 10px;
    width: 100px;
    cursor: pointer;
    color: var(--color-main);
    transition: color 0.5s;
    background: transparent;
}

.list_item:hover {
    color: transparent;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 0, 0, 1) 14%,
        rgba(255, 255, 0, 1) 28%,
        rgba(0, 255, 0, 1) 43%,
        rgba(0, 255, 255, 1) 58%,
        rgba(255, 0, 255, 1) 73%,
        rgba(0, 0, 255, 1) 87%,
        rgba(0, 0, 0, 1) 100%
    );
    font-weight: 600;
    animation: flow 5s ease-in-out infinite;
    background-size: 300%;
}

@keyframes flow {
    0% {
        background-position: 0 50%;
        background-clip: text;
    }
    50% {
        background-position: 100% 50%;
        background-clip: text;
    }
    100% {
        background-position: 0 50%;
        background-clip: text;
    }
}

.active {
    text-align: center;
    text-decoration: none;
    color: var(--color-main);
    width: 100px;
}

.indicator {
    position: absolute;
    background: var(--color-main);
    width: 100px;
    height: 3px;
    top: 100%;
    transform: translateX(-300px);
    transition: transform 0.5s, background 0.3s;
}

.list_item_active:hover ~ .indicator {
    background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 0, 0, 1) 14%,
            rgba(255, 255, 0, 1) 28%,
            rgba(0, 255, 0, 1) 43%,
            rgba(0, 255, 255, 1) 58%,
            rgba(255, 0, 255, 1) 73%,
            rgba(0, 0, 255, 1) 87%,
            rgba(0, 0, 0, 1) 100%
    );
    animation: flow-indicator 5s ease-in-out infinite;
    background-size: 300%;
}

@keyframes flow-indicator {
    0% {
        background-position: 0 50%;
        background-clip: content-box;
    }
    50% {
        background-position: 100% 50%;
        background-clip: content-box;
    }
    100% {
        background-position: 0 50%;
        background-clip: content-box;
    }
}

.nav ul li:nth-child(1).list_item_active ~ .indicator {
    transform: translateX(-300px);
}

.nav ul li:nth-child(2).list_item_active ~ .indicator {
    transform: translateX(-200px);
}

.nav ul li:nth-child(3).list_item_active ~ .indicator {
    transform: translateX(-100px);
}

.nav ul li:nth-child(4).list_item_active ~ .indicator {
    transform: translateX(0);
}

@media screen and (max-width: 1024px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1024px;
    }
}

@media screen and (max-width: 600px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 600px;
    }
    .logo {
        display: none;
    }
    .list {
        position: relative;
        display: flex;
        list-style: none;
        justify-content: center;
    }
    .indicator {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .list_item {
        font-size: 12px;
        width: 75px;
    }
}
