.container {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: 700;
  height: calc(80vh + 64px);
  width: 1200px;
  user-select: none;
  color: var(--color-main);
}

.greeting {

}

.competence {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 64px;
  text-align: center;
  margin-bottom: 50px;
}

.comp_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
  gap: 50px;
}

@media screen and (max-width: 1024px) {
  .content {
    display: flex;
    align-items: center;
    font-size: 48px;
    justify-content: center;
    width: 550px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .content {
    font-size: 18px;
    width: 300px;
  }
}
