.card {
    position: relative;
    width: 350px;
    height: 200px;
    border: 1px solid var(--color-main);
    border-radius: 20px;
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.title {
    margin: 10px 0;
    font-size: 30px;
    text-align: center;
}

.description {
    font-size: 20px;
    margin: 0 20px;
}

