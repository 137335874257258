@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700;900&display=swap");

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html[data-theme="dark"] {
    --color-main: #fff;
    --background: #000;
}

html[data-theme="light"] {
    --color-main: #000;
    --background: #fff;
}

body {
    background-color: var(--background);
    overflow: hidden;
    display: flex;
    justify-content: center;
    font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transition {
    transition: background-color 0.5s;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
